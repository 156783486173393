import { Fragment, useEffect } from "react";
import { useParams } from "react-router";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { useStateRef } from "../../../utils";
import { User, UsersArea, UsersContainer } from "./styles";

interface IUser {
  name: string;
  login: string;
}

export const Queue: React.FC = () => {
  const [users, setUsers, usersRef] = useStateRef<Array<IUser>>([]);
  const [usersInQueue, setUsersInQueue, usersInQueueRef] = useStateRef<
    Array<IUser>
  >([]);
  const { clientName } = useParams<{ clientName: string }>();

  const getUsers = async () => {
    const queueUsersResponse = await request({
      method: "GET",
      path: `users/${clientName}/queue`,
      showSuccessMessage: false,
    });

    const allUsersResponse = await request({
      method: "GET",
      path: `users`,
      showSuccessMessage: false,
    });

    if (!queueUsersResponse.error && !allUsersResponse.error) {
      let allUsers: Array<IUser> = allUsersResponse;
      let queueUsers: Array<IUser> = queueUsersResponse;

      setUsersInQueue(queueUsers);
      setUsers(normalizeUsers(allUsers, queueUsers));
    }
  };

  const normalizeUsers = (allUsers: Array<IUser>, queueUsers: Array<IUser>) => {
    return allUsers.filter(
      (u) => !queueUsers.find((ui) => ui.login === u.login)
    );
  };

  const handleSave = async () => {
    await request({
      method: "PUT",
      timeout: 99999999,
      path: `users/${clientName}/queue`,
      data: { users: usersInQueue.map((u) => u.login) },
    });
  };

  const handleAddRemoveUsuerQueue = (
    type: "add" | "remove",
    login: string,
    name: string
  ) => {
    if (type === "add") {
      setUsersInQueue((oldUsersInQueue: Array<IUser>) => [
        ...oldUsersInQueue,
        { login, name },
      ]);
      setUsers(normalizeUsers(usersRef.current, usersInQueueRef.current));
      return;
    }

    if (type === "remove") {
      let oldUsers = usersInQueue;
      let findUser = usersInQueue.findIndex((oU: IUser) => oU.login === login);
      oldUsers.splice(findUser, 1);

      setUsersInQueue(oldUsers);
      setUsers((oUse: Array<IUser>) => [{ login, name }, ...oUse]);
    }
  };

  useEffect(() => {
    getUsers();
  }, []); //eslint-disable-line

  return (
    <Page header={`Fila - ${clientName}`}>
      <Fragment></Fragment>
      <UsersContainer>
        <UsersArea>
          <p>Usúarios que não estão na fila ({users.length})</p>
          {users.map((u) => (
            <User
              key={u.login}
              onClick={() => handleAddRemoveUsuerQueue("add", u.login, u.name)}
            >
              {u.name} - {u.login}
            </User>
          ))}
        </UsersArea>
        <UsersArea>
          <p>Usúarios na fila ({usersInQueue.length})</p>
          {usersInQueue.map((u) => (
            <User
              key={u.login}
              onClick={() =>
                handleAddRemoveUsuerQueue("remove", u.login, u.name)
              }
            >
              {u.name} - {u.login}
            </User>
          ))}
        </UsersArea>
      </UsersContainer>

      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
