import styled from "styled-components";
import { DefaultColors } from "../../../defaultColors";

export const NewButton = styled.button`
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  background: ${DefaultColors.NEW_BUTTON};
  font-size: 12px;
  font-weight: 400;
  border: none;
  cursor: pointer;

  :hover {
    background: ${DefaultColors.NEW_BUTTON_HOVER};
  }
`;
