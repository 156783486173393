import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import {
  SecondaryAreaButton,
  SecondaryButton,
} from "../../components/Buttons/SecondaryButton";
import { Page } from "../../components/Page";
import { Table } from "../../components/Table";
import { request } from "../../services/request";

interface IClient {
  name: string;
}

export const Clients: React.FC = () => {
  const [clients, setClients] = useState<Array<IClient>>([]);
  const history = useHistory();

  const gotToPage = (route: string) => {
    history.push(route);
  };

  const columns = [
    {
      name: "name",
      label: "name",
    },
    {
      name: "plutoLink",
      label: "Link que o cliente deve colocar no site",
      options: {
        filter: false,
        customBodyRender: (v: string) => {
          return (
            <a href={v} target="_blank" rel="noreferrer">
              {v}
            </a>
          );
        },
      },
    },
    {
      name: "name",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: string) => {
          return (
            <SecondaryAreaButton>
              <SecondaryButton
                onClick={() => gotToPage(`clientes/${v}/tabulacoes`)}
              >
                tabulações
              </SecondaryButton>
              <SecondaryButton
                onClick={() => gotToPage(`clientes/${v}/convidados`)}
              >
                convidados
              </SecondaryButton>
              <SecondaryButton onClick={() => gotToPage(`clientes/${v}/fila`)}>
                fila
              </SecondaryButton>
              <SecondaryButton
                onClick={() => gotToPage(`clientes/${v}/editar`)}
              >
                vídeos/cores/logo
              </SecondaryButton>
            </SecondaryAreaButton>
          );
        },
      },
    },
  ];

  const getClients = async () => {
    const response = await request({
      method: "GET",
      path: "clients?minimum=true",
      showSuccessMessage: false,
    });

    if (!response.error) {
      setClients(response);
    }
  };

  const handleNeUser = () => {
    history.push("/clientes/novo");
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <Page header="Clientes">
      <PrimaryButton text="+ novo cliente" onClick={handleNeUser} />
      <Table columns={columns} title="" data={clients} />
    </Page>
  );
};
