import { Fragment, useEffect, useState } from "react";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputFile } from "../../../components/FormInputs/File";
import { FormInputText } from "../../../components/FormInputs/Input";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { toSeoUrl } from "../../../utils";
import sweetAlert from "../../../utils/sweetAlert";

export const NewClient: React.FC = () => {
  const [client, setClient] = useState<{ [key: string]: any }>({});

  const handleSave = async () => {
    if (!client.name) {
      sweetAlert.error("Nome do cliente é obrigatório");
      return;
    }

    await request({
      loadingMessage: "Criando cliente e enviando arquivos, aguarde...",
      timeout: 999999999,
      method: "POST",
      path: `clients`,
      data: client,
    });
  };

  const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader && reader.result) resolve(reader.result.toString());
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleOnChangeInput = async (value: any, key: string) => {
    if (key.includes("VIDEO") || key.includes("LOGO")) {
      value = await file2Base64(value[0]);
    }

    if (key === "name") {
      value = toSeoUrl(value);
    }

    setClient((oldClient) => {
      return {
        ...oldClient,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    // getGuests();
  }, []); //eslint-disable-line

  return (
    <Page header="Novo Cliente">
      <Fragment></Fragment>
      <FormArea>
        <FormInputText
          label="Nome"
          name="name"
          onChange={handleOnChangeInput}
          width="100%"
          defaultValue={client.name ? client.name : ""}
        />
        <h5>Logo</h5>
        <FormInputFile
          label="Página da NPS"
          name="CLIENT_LOGO_NPS"
          onChange={handleOnChangeInput}
          width="33%"
          accept=".png,.svg"
        />
        <FormInputFile
          label="Página da fila"
          name="CLIENT_LOGO_QUEUE"
          onChange={handleOnChangeInput}
          width="33%"
          accept=".png,.svg"
        />
        <FormInputFile
          label="Tela de liberação da camêra do atendente"
          name="CLIENT_LOGO_DARK"
          onChange={handleOnChangeInput}
          width="33%"
          accept=".png,.svg"
        />{" "}
        <h5>
          Cores <small>(valor em hexadecimal ex: #fff)</small>
        </h5>
        <FormInputText
          label="Cor do fundo da fila"
          name="BACKGROUND_COLOR_QUEUE"
          onChange={handleOnChangeInput}
          width="22%"
        />
        <FormInputText
          label="Cor do fundo na página de NPS"
          name="BACKGROUND_COLOR_NPS"
          onChange={handleOnChangeInput}
          width="22%"
        />
        <FormInputText
          label="Cor do texto na NPS"
          name="TEXT_COLOR_NPS"
          onChange={handleOnChangeInput}
          width="22%"
        />
        <FormInputText
          label="Cor do texto na fila"
          name="TEXT_COLOR_QUEUE"
          onChange={handleOnChangeInput}
          width="22%"
        />
        <h5>Vídeos desktop </h5>
        <FormInputFile
          label="Fila de espera"
          name="DESKTOP_VIDEO_QUEUE"
          onChange={handleOnChangeInput}
          width="25%"
          accept=".mp4"
        />
        <FormInputFile
          label="Fora do horário de atendimento"
          name="DESKTOP_VIDEO_OUT_OF_HOURS_QUEUE"
          onChange={handleOnChangeInput}
          width="25%"
          accept=".mp4"
        />
        <FormInputFile
          label="Página de liberação de camêra do visitante"
          name="DESKTOP_VIDEO_PRE_ROOM"
          onChange={handleOnChangeInput}
          width="25%"
          accept=".mp4"
        />
        <FormInputFile
          label="NPS"
          name="DESKTOP_VIDEO_NPS"
          onChange={handleOnChangeInput}
          width="22%"
          accept=".mp4"
        />
        <FormInputFile
          label="NPS agradecimento"
          name="DESKTOP_VIDEO_SECOND_NPS"
          onChange={handleOnChangeInput}
          width="22%"
          accept=".mp4"
        />
        <h5>Vídeos mobile </h5>
        <FormInputFile
          label="Fila de espera"
          name="MOBILE_VIDEO_QUEUE"
          onChange={handleOnChangeInput}
          width="22%"
          accept=".gif"
        />
        <FormInputFile
          label="Fora do horário de atendimento"
          name="MOBILE_VIDEO_OUT_OF_HOURS_QUEUE"
          onChange={handleOnChangeInput}
          width="22%"
          accept=".gif"
        />
        <FormInputFile
          label="Página de liberação de camêra do visitante"
          name="MOBILE_VIDEO_PRE_ROOM"
          onChange={handleOnChangeInput}
          width="22%"
          accept=".gif"
        />
        <FormInputFile
          label="NPS"
          name="MOBILE_VIDEO_NPS"
          onChange={handleOnChangeInput}
          width="22%"
          accept=".gif"
        />
        <FormInputFile
          label="NPS agradecimento"
          name="MOBILE_VIDEO_SECOND_NPS"
          onChange={handleOnChangeInput}
          width="22%"
          accept=".gif"
        />
      </FormArea>

      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
