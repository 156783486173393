import { Fragment, useEffect, useState } from "react";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputFile } from "../../../components/FormInputs/File";
import { FormInputText } from "../../../components/FormInputs/Input";
import { Select } from "../../../components/FormInputs/Select";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { toSeoUrl } from "../../../utils";
import sweetAlert from "../../../utils/sweetAlert";

export const NewUser: React.FC = () => {
  const [user, setUser] = useState<{ [key: string]: any }>({});
  const [userTypes, setUserTypes] = useState<
    Array<{ name: string; _id: string }>
  >([]);

  const handleSave = async () => {
    if (!user.name) {
      sweetAlert.error("Nome é obrigatório");
      return;
    }

    if (!user.login) {
      sweetAlert.error("Login é obrigatório");
      return;
    }

    if (!user.userTypeId) {
      sweetAlert.error("Permissão  é obrigatório");
      return;
    }

    await request({
      loadingMessage: "Criando usuário e enviando foto, aguarde...",
      method: "POST",
      path: `users`,
      timeout: 9999999,
      data: user,
    });
  };

  const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader && reader.result) resolve(reader.result.toString());
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleOnChangeInput = async (value: any, key: string) => {
    if (key.includes("avatar")) {
      value = await file2Base64(value[0]);
    }

    if (key === "login") {
      value = toSeoUrl(value);
    }

    setUser((oldUser) => {
      return {
        ...oldUser,
        [key]: value,
      };
    });
  };

  const getUserTypes = async () => {
    const res = await request({
      method: "GET",
      path: `users/types`,
      showSuccessMessage: false,
    });

    if (!res.error) {
      setUserTypes(res);
    }
  };

  useEffect(() => {
    getUserTypes();
  }, []); //eslint-disable-line

  return (
    <Page header="Novo Usuário">
      <Fragment></Fragment>
      <FormArea>
        <FormInputText
          label="Nome"
          name="name"
          onChange={handleOnChangeInput}
          width="33%%"
        />
        <FormInputText
          label="Login"
          name="login"
          onChange={handleOnChangeInput}
          width="33%"
          defaultValue={user.login ? user.login : ""}
        />
        <FormInputFile
          label="Avatar"
          name="avatar"
          onChange={handleOnChangeInput}
          width="33%"
          accept=".png,.svg"
        />
        <Select
          label="Permissão"
          name="userTypeId"
          onChange={handleOnChangeInput}
          options={userTypes.map((u) => {
            return {
              name: u.name,
              value: u._id,
            };
          })}
        />
      </FormArea>

      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
