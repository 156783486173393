import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputFile } from "../../../components/FormInputs/File";
import { FormInputText } from "../../../components/FormInputs/Input";
import { Select } from "../../../components/FormInputs/Select";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { toSeoUrl } from "../../../utils";
import { AreaInputLink } from "./styles";

export const EditClient: React.FC = () => {
  const [clientId, setClientId] = useState<string>();
  const [client, setClient] = useState<{ [key: string]: any }>({});
  const [oldClient, setOldClient] = useState<{ [key: string]: any }>({});
  const { clientName } = useParams<{ clientName: string }>();

  const handleSave = async () => {
    await request({
      loadingMessage: "Atualizando cliente e enviando arquivos, aguarde...",
      timeout: 999999999,
      method: "PUT",
      path: `clients/${clientId}/assets`,
      data: client,
    });
  };

  const getClient = async () => {
    const response = await request({
      method: "GET",
      path: `clients/${clientName}`,
      showSuccessMessage: false,
    });

    if (!response.error) {
      setClientId(response._id);
      setOldClient(response.assets ? response.assets : {});
    }
  };

  const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader && reader.result) resolve(reader.result.toString());
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleOnChangeInput = async (value: any, key: string) => {
    if (key.includes("VIDEO") || key.includes("LOGO")) {
      value = await file2Base64(value[0]);
    }

    if (key.includes("commentText")) {
      value = value === "sim" ? true : false;
    }

    if (key === "name") {
      value = toSeoUrl(value);
    }

    setClient((oldClient) => {
      return {
        ...oldClient,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    getClient();
  }, []); //eslint-disable-line

  return (
    <Page header={`Editar - ${clientName}`}>
      <Fragment></Fragment>
      {oldClient && (
        <FormArea>
          <h5>Logo</h5>
          <AreaInputLink>
            <FormInputFile
              label="Página da NPS"
              name="CLIENT_LOGO_NPS"
              onChange={handleOnChangeInput}
              width="33%"
              accept=".png,.svg"
            />
            {oldClient.CLIENT_LOGO_NPS && (
              <a
                href={oldClient.CLIENT_LOGO_NPS}
                target="_blank"
                rel="noreferrer"
              >
                link do logo
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Página da fila"
              name="CLIENT_LOGO_QUEUE"
              onChange={handleOnChangeInput}
              width="33%"
              accept=".png,.svg"
            />
            {oldClient.CLIENT_LOGO_QUEUE && (
              <a
                href={oldClient.CLIENT_LOGO_QUEUE}
                target="_blank"
                rel="noreferrer"
              >
                link do logo
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Tela de liberação da camêra do atendente"
              name="CLIENT_LOGO_DARK"
              onChange={handleOnChangeInput}
              width="33%"
              accept=".png,.svg"
            />
            {oldClient.CLIENT_LOGO_DARK && (
              <a
                href={oldClient.CLIENT_LOGO_DARK}
                target="_blank"
                rel="noreferrer"
              >
                link do logo
              </a>
            )}
          </AreaInputLink>{" "}
          <h5>
            Cores <small>(valor em hexadecimal ex: #fff)</small>
          </h5>
          <AreaInputLink>
            <FormInputText
              label="Cor do fundo da fila"
              name="BACKGROUND_COLOR_QUEUE"
              onChange={handleOnChangeInput}
              width="22%"
              defaultValue={oldClient.BACKGROUND_COLOR_QUEUE}
            />
          </AreaInputLink>
          <AreaInputLink>
            <FormInputText
              label="Cor do fundo na página de NPS"
              name="BACKGROUND_COLOR_NPS"
              onChange={handleOnChangeInput}
              width="22%"
              defaultValue={oldClient.BACKGROUND_COLOR_NPS}
            />
          </AreaInputLink>
          <AreaInputLink>
            <FormInputText
              label="Cor do texto na NPS"
              name="TEXT_COLOR_NPS"
              onChange={handleOnChangeInput}
              width="22%"
              defaultValue={oldClient.TEXT_COLOR_NPS}
            />
          </AreaInputLink>
          <AreaInputLink>
            <FormInputText
              label="Cor do texto na fila"
              name="TEXT_COLOR_QUEUE"
              onChange={handleOnChangeInput}
              width="22%"
              defaultValue={oldClient.TEXT_COLOR_QUEUE}
            />
          </AreaInputLink>
          <h5>Vídeos desktop </h5>
          <AreaInputLink>
            <FormInputFile
              label="Fila de espera"
              name="DESKTOP_VIDEO_QUEUE"
              onChange={handleOnChangeInput}
              width="25%"
              accept=".mp4"
            />{" "}
            {oldClient.DESKTOP_VIDEO_QUEUE && (
              <a
                href={oldClient.DESKTOP_VIDEO_QUEUE}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo desktop
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Fora do horário de atendimento"
              name="DESKTOP_VIDEO_OUT_OF_HOURS_QUEUE"
              onChange={handleOnChangeInput}
              width="25%"
              accept=".mp4"
            />{" "}
            {oldClient.DESKTOP_VIDEO_OUT_OF_HOURS_QUEUE && (
              <a
                href={oldClient.DESKTOP_VIDEO_OUT_OF_HOURS_QUEUE}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo desktop
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Página de liberação de camêra do visitante"
              name="DESKTOP_VIDEO_PRE_ROOM"
              onChange={handleOnChangeInput}
              width="25%"
              accept=".mp4"
            />{" "}
            {oldClient.DESKTOP_VIDEO_PRE_ROOM && (
              <a
                href={oldClient.DESKTOP_VIDEO_PRE_ROOM}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo desktop
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="NPS"
              name="DESKTOP_VIDEO_NPS"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".mp4"
            />{" "}
            {oldClient.DESKTOP_VIDEO_NPS && (
              <a
                href={oldClient.DESKTOP_VIDEO_NPS}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo desktop
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="NPS Agradecimento"
              name="DESKTOP_VIDEO_SECOND_NPS"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".mp4"
            />{" "}
            {oldClient.DESKTOP_VIDEO_SECOND_NPS && (
              <a
                href={oldClient.DESKTOP_VIDEO_SECOND_NPS}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo desktop
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Navegador não suportado"
              name="DESKTOP_VIDEO_NOT_SUPORTED_DESKTOP"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".mp4"
            />{" "}
            {oldClient.DESKTOP_VIDEO_NOT_SUPORTED_DESKTOP && (
              <a
                href={oldClient.DESKTOP_VIDEO_NOT_SUPORTED_DESKTOP}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo desktop
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Agradecimento de visitante ouvinte"
              name="LISTERNER_VISITANT_DESKTOP"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".mp4"
            />{" "}
            {oldClient.LISTERNER_VISITANT_DESKTOP && (
              <a
                href={oldClient.LISTERNER_VISITANT_DESKTOP}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo desktop
              </a>
            )}
          </AreaInputLink>
          <h5>Vídeos mobile </h5>
          <AreaInputLink>
            <FormInputFile
              label="Fila de espera"
              name="MOBILE_VIDEO_QUEUE"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".gif"
            />{" "}
            {oldClient.MOBILE_VIDEO_QUEUE && (
              <a
                href={oldClient.MOBILE_VIDEO_QUEUE}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo mobile
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Fora do horário de atendimento"
              name="MOBILE_VIDEO_OUT_OF_HOURS_QUEUE"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".gif"
            />{" "}
            {oldClient.MOBILE_VIDEO_OUT_OF_HOURS_QUEUE && (
              <a
                href={oldClient.MOBILE_VIDEO_OUT_OF_HOURS_QUEUE}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo mobile
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Página de liberação de camêra do visitante"
              name="MOBILE_VIDEO_PRE_ROOM"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".gif"
            />{" "}
            {oldClient.MOBILE_VIDEO_PRE_ROOM && (
              <a
                href={oldClient.MOBILE_VIDEO_PRE_ROOM}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo mobile
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="NPS"
              name="MOBILE_VIDEO_NPS"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".gif"
            />{" "}
            {oldClient.MOBILE_VIDEO_NPS && (
              <a
                href={oldClient.MOBILE_VIDEO_NPS}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo mobile
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="NPS Agradecimento"
              name="MOBILE_VIDEO_SECOND_NPS"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".gif"
            />{" "}
            {oldClient.MOBILE_VIDEO_SECOND_NPS && (
              <a
                href={oldClient.MOBILE_VIDEO_SECOND_NPS}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo mobile
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Navegador não suportado"
              name="MOBILE_VIDEO_NOT_SUPORTED_DESKTOP"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".gif"
            />{" "}
            {oldClient.MOBILE_VIDEO_NOT_SUPORTED_DESKTOP && (
              <a
                href={oldClient.MOBILE_VIDEO_NOT_SUPORTED_DESKTOP}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo mobile
              </a>
            )}
          </AreaInputLink>
          <AreaInputLink>
            <FormInputFile
              label="Agradecimento ouvinte"
              name="LISTERNER_VISITANT_MOBILE"
              onChange={handleOnChangeInput}
              width="22%"
              accept=".gif"
            />{" "}
            {oldClient.LISTERNER_VISITANT_MOBILE && (
              <a
                href={oldClient.LISTERNER_VISITANT_MOBILE}
                target="_blank"
                rel="noreferrer"
              >
                link do vídeo mobile
              </a>
            )}
          </AreaInputLink>
          <h5>Opções do cliente</h5>
          <AreaInputLink>
            <FormInputText
              label="Link para retornar para o app"
              name="linkReturnNps"
              onChange={handleOnChangeInput}
              width="22%"
              defaultValue={oldClient.linkReturnNps}
            />
          </AreaInputLink>
          {oldClient.commentText !== undefined && (
            <AreaInputLink>
              <Select
                label="Campo de comentário na NPS "
                name="commentText"
                defaultValue={oldClient.commentText === true ? "sim" : "não"}
                onChange={handleOnChangeInput}
                options={["sim", "não"].map((u) => {
                  return {
                    name: u,
                    value: u,
                  };
                })}
              />
            </AreaInputLink>
          )}
        </FormArea>
      )}
      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
