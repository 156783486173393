import React from "react";
import { Switch } from "react-router-dom";
import { Clients } from "../pages/Clients";
import { EditClient } from "../pages/Clients/Edit";
import { Guests } from "../pages/Clients/Guests";
import { NewClient } from "../pages/Clients/New";
import { Queue } from "../pages/Clients/Queue";
import { Tabbings } from "../pages/Clients/Tabbings";
import { Dashboard } from "../pages/Dashboard";
import { Login } from "../pages/Login";
import { Users } from "../pages/Users";
import { EditUser } from "../pages/Users/Edit";
import { NewUser } from "../pages/Users/New";
import { PrivateRoute } from "./PrivateRoute";
import { Route } from "./Route";

export const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <PrivateRoute path="/" exact component={Dashboard} />
    <PrivateRoute path="/usuarios" exact component={Users} />
    <PrivateRoute path="/clientes" exact component={Clients} />
    <PrivateRoute
      path="/clientes/:clientName/convidados"
      exact
      component={Guests}
    />
    <PrivateRoute
      path="/clientes/:clientName/tabulacoes"
      exact
      component={Tabbings}
    />
    <PrivateRoute path="/clientes/:clientName/fila" exact component={Queue} />
    <PrivateRoute
      path="/clientes/:clientName/editar"
      exact
      component={EditClient}
    />
    <PrivateRoute path="/usuarios/novo" exact component={NewUser} />
    <PrivateRoute path="/usuarios/:userId/editar" exact component={EditUser} />
    <PrivateRoute path="/clientes/novo" exact component={NewClient} />
  </Switch>
);
