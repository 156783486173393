import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import sweetAlert from "../../../utils/sweetAlert";
import { GuestTexArea } from "./styles";

export const Guests: React.FC = () => {
  const [guests, setGuests] = useState<string>("");
  const { clientName } = useParams<{ clientName: string }>();

  const getGuests = async () => {
    const response = await request({
      method: "GET",
      path: `clients/${clientName}`,
      showSuccessMessage: false,
    });

    if (!response.error) {
      setGuests(response.guests.join("\n"));
    }
  };

  const formatNames = (value: string) => {
    let valueInArray: Array<string> = value.split("\n");
    valueInArray = valueInArray.filter((v) => v !== "");

    let existDuplicates: Array<string> = [];

    valueInArray.forEach((v, index) => {
      if (valueInArray.includes(v, index + 1)) existDuplicates.push(v);
    });

    if (existDuplicates.length > 0) {
      return { values: existDuplicates, success: false };
    }

    return { values: valueInArray, success: true };
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    setGuests(value);

    let values = formatNames(value);

    if (!values.success) {
      sweetAlert.error(
        `Nomes duplicados, remova-os: \n ${values.values.join("\n")}`
      );
    }
  };

  const handleSave = async () => {
    let values = formatNames(guests);

    if (!values.success) {
      sweetAlert.error(
        `Nomes duplicados, remova-os: \n ${values.values.join("\n")}`
      );
      return;
    }

    await request({
      method: "PUT",
      path: `clients/${clientName}/guests`,
      data: { guests: values.values },
    });
  };

  useEffect(() => {
    getGuests();
  }, []); //eslint-disable-line

  return (
    <Page header={`Convidados - ${clientName}`}>
      <Fragment></Fragment>
      <Fragment>
        <p>Convidados devem ser separados por enter</p>
        <GuestTexArea onChange={handleChangeTextArea} value={guests} />
      </Fragment>
      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
