import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f1f1f1;
`;

export const ContentArea = styled.div`
  padding: 20px;
  width: 100%;
  height: calc(100% - 42px);
  overflow: auto;
`;

export const Title = styled.h1`
  padding: 10px 10px 10px 0px;
  width: calc(100% - 10px);
  border-bottom: 1px solid #1f0533;
  font-size: 20px;
  font-weight: 600;
`;
