import { useEffect, useState } from "react";
import { FaDashcube, FaUsers } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { clearLs } from "../../localStorage";
import { ExitButton, Link, Menu, NavArea, ProfileArea } from "./styles";

interface IMenu {
  name: string;
  title: string;
  path: string;
  icon: IconType;
  active?: boolean;
}

interface ISideBar {
  setMenuTitle: Function;
}

export const SideBar: React.FC<ISideBar> = ({ setMenuTitle }) => {
  const [menu, setMenu] = useState<Array<IMenu>>([
    {
      icon: FaDashcube,
      name: "Dashboard",
      path: "/",
      title: "Dashboard",
    },
    {
      icon: FaUsers,
      name: "Usuários",
      path: "/usuarios",
      title: "Usuários",
    },
    {
      icon: FaUsers,
      name: "Clientes",
      path: "/clientes",
      title: "Clientes",
    },
  ]);

  const { getPayload } = useAuth();
  const route = useHistory();

  const changeTitleHead = (title: string) => {
    let defaultTitle = "DISNEY v2";
    const titleEl = document.querySelector("title");
    if (titleEl) {
      titleEl.text = `${defaultTitle} - ${title}`;
    }

    setMenuTitle(title);
  };

  const handleExitButton = () => {
    clearLs();
    route.push("/login");
  };

  useEffect(() => {
    let newMenu: Array<IMenu> = [];
    let title = "";
    menu.forEach((m) => {
      if (route.location.pathname === "/" && m.path === "/") {
        m.active = true;
        title = m.title;
      } else if (
        route.location.pathname.indexOf(m.path) > -1 &&
        m.path !== "/"
      ) {
        m.active = true;
        title = m.title;
      } else {
        m.active = false;
      }
      newMenu.push(m);
    });
    setMenu(newMenu);
    changeTitleHead(title);
  }, [route.location.pathname]); // eslint-disable-line

  return (
    <NavArea>
      <ProfileArea>
        <p>{getPayload().name}</p>
      </ProfileArea>
      <Menu>
        {menu.map((menu) => {
          const Icon = menu.icon;
          return (
            <Link
              to={menu.path}
              linkactive={menu.active ? "true" : "false"}
              key={menu.path}
            >
              <li key={menu.title}>
                <Icon />
                <span>{menu.title}</span>
              </li>
            </Link>
          );
        })}
      </Menu>
      <ExitButton onClick={handleExitButton}>Sair</ExitButton>
    </NavArea>
  );
};
