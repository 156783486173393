import { Redirect, RouteProps as ReactDomRouteProps } from "react-router";
import { SideBar } from "../../components/SideBar";
import { useAuth } from "../../hooks/auth";
import { useHeader } from "../../hooks/headerHook";
import { Route } from "../Route";
import { Container, ContentArea, Title } from "./styles";

interface RouteProps extends ReactDomRouteProps {
  component: React.ComponentType;
  path: string;
  exact: boolean;
}

export const PrivateRoute: React.FC<RouteProps> = ({ ...rest }) => {
  const { getPayload } = useAuth();
  const { header, setHeader } = useHeader();
  if (!getPayload()._id) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  return (
    <Container>
      <SideBar setMenuTitle={setHeader} />
      <ContentArea>
        <Title>{header}</Title>
        <Route {...rest} />
      </ContentArea>
    </Container>
  );
};
