import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputFile } from "../../../components/FormInputs/File";
import { FormInputText } from "../../../components/FormInputs/Input";
import { Select } from "../../../components/FormInputs/Select";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { toSeoUrl } from "../../../utils";
import sweetAlert from "../../../utils/sweetAlert";
import { AreaInputLink } from "./styles";

export const EditUser: React.FC = () => {
  const [user, setUser] = useState<{ [key: string]: any }>({});
  const [oldUser, setOldUser] = useState<{ [key: string]: any }>({});
  const [userTypes, setUserTypes] = useState<
    Array<{ name: string; _id: string }>
  >([]);
  const { userId }: { userId: string } = useParams();

  const handleSave = async () => {
    if (!user.name) {
      sweetAlert.error("Nome é obrigatório");
      return;
    }

    if (!user.login) {
      sweetAlert.error("Login é obrigatório");
      return;
    }

    if (!user.userTypeId) {
      sweetAlert.error("Permissão  é obrigatório");
      return;
    }

    await request({
      loadingMessage: "Atualizando usuário e enviando foto, aguarde...",
      method: "PUT",
      timeout: 9999,
      path: `users/${userId}`,
      data: user,
    });
  };

  const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader && reader.result) resolve(reader.result.toString());
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleOnChangeInput = async (value: any, key: string) => {
    if (key.includes("avatar")) {
      value = await file2Base64(value[0]);
    }

    if (key === "login") {
      value = toSeoUrl(value);
    }

    setUser((ou) => {
      return {
        ...ou,
        [key]: value.trim(),
      };
    });
  };

  const getUserTypes = async () => {
    const res = await request({
      method: "GET",
      path: `users/types`,
      showSuccessMessage: false,
    });

    if (!res.error) {
      setUserTypes(res);
    }
  };

  const getUser = async () => {
    const res = await request({
      method: "GET",
      path: `users/${userId}`,
      showSuccessMessage: false,
    });

    if (!res.error) {
      await getUserTypes();
      setOldUser(res);
      handleOnChangeInput(res.userTypeId, "userTypeId");
      handleOnChangeInput(res.name, "name");
      handleOnChangeInput(res.login, "login");
    }
  };

  useEffect(() => {
    getUser();
  }, []); //eslint-disable-line

  return (
    <Page header="Novo Usuário">
      <Fragment></Fragment>
      <FormArea>
        <FormInputText
          label="Nome"
          name="name"
          onChange={handleOnChangeInput}
          width="33%%"
          defaultValue={user.name ? user.name : oldUser.name}
        />
        <FormInputText
          label="Login"
          name="login"
          onChange={handleOnChangeInput}
          width="33%"
          defaultValue={user.login ? user.login : oldUser.login}
        />
        <AreaInputLink>
          <FormInputFile
            label="Avatar"
            name="avatar"
            onChange={handleOnChangeInput}
            width="33%"
            accept=".png,.svg"
          />
          {oldUser.avatar && (
            <a href={oldUser.avatar} target="_blank" rel="noreferrer">
              link do avatar
            </a>
          )}
        </AreaInputLink>
        {oldUser.userTypeId && (
          <Select
            label="Permissão"
            name="userTypeId"
            onChange={handleOnChangeInput}
            defaultValue={
              user.userTypeId ? user.userTypeId : oldUser.userTypeId
            }
            options={userTypes.map((u) => {
              return {
                name: u.name,
                value: u._id,
              };
            })}
          />
        )}

        <Select
          label="Ressetar a senha"
          name="ressetPass"
          onChange={handleOnChangeInput}
          defaultValue=""
          options={[{ name: "Sim", value: "sim" }]}
        />
      </FormArea>

      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
