import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";
import { DefaultColors } from "../../defaultColors";
import { Button } from "../Buttons/SecondaryButton/styles";

export const NavArea = styled.nav`
  position: relative;
  width: 200px;
  height: calc(100vh - 18px);
  background-color: ${DefaultColors.NAV_BACKGROUND};
  color: #fff;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  padding: 20px 0px 0px 20px;
`;

export const ProfileArea = styled.div``;

export const Menu = styled.ul`
  margin-top: 20px;
  width: 100%;
`;

export const Link = styled(ReactRouterLink)`
  margin-top: 5px;
  cursor: pointer;
  height: 20px;
  width: calc (100% - 20px);
  padding: 20px 0px 20px 20px;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: ${({ linkactive }: { linkactive: string }) =>
    linkactive === "true"
      ? DefaultColors.NAV_LINK_COLOR_ACTIVE
      : "transparent"};

  li {
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  :hover {
    background: ${DefaultColors.NAV_LINK_COLOR_ACTIVE};

    transition: 0.5s;
  }
`;

export const ExitButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  margin: 0 auto;
`;
