import styled from "styled-components";

export const UsersArea = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  width: 40%;
  overflow: auto;

  :last-of-type {
    margin-left: 20px;
  }
`;

export const User = styled.div`
  white-space: nowrap;
  margin: 10px 0px 10px 0px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  :hover {
    background-color: #ccc;
  }
`;

export const UsersContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  overflow: auto;

  p {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
`;
