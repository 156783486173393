import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../components/Buttons/SecondaryButton";
import { Page } from "../../components/Page";
import { Table } from "../../components/Table";
import { request } from "../../services/request";
import { Avatar } from "./style";

interface IUser {
  clients: Array<string>;
  name: string;
  login: string;
  active: Boolean;
  activeString: string;
  userType: {
    name: string;
  };
}

export const Users: React.FC = () => {
  const [users, setUsers] = useState<Array<IUser>>([]);
  const columns = [
    {
      name: "avatar",
      label: "",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          return (
            <a href={v} target="_blank" rel="noreferrer">
              <Avatar src={v} alt="avatar" />
            </a>
          );
        },
      },
    },
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "login",
      label: "Login",
    },
    {
      label: "Permissão",
      name: "userType",
      options: {
        customBodyRender: (value: any) => value.name,
      },
    },
    {
      name: "activeString",
      label: "Ativo",
    },
    {
      name: "_id",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          return (
            <SecondaryButton onClick={() => gotToPage(`usuarios/${v}/editar`)}>
              editar
            </SecondaryButton>
          );
        },
      },
    },
  ];
  const history = useHistory();

  const gotToPage = (route: string) => {
    history.push(route);
  };

  const getUsers = async () => {
    const response = await request({
      method: "GET",
      path: "users",
      showSuccessMessage: false,
    });

    if (!response.error) {
      setUsers(
        response.map((u: IUser) => {
          u.activeString = u.active ? "Sim" : "Não";
          return u;
        })
      );
    }
  };

  const handleNeUser = () => {
    gotToPage(`usuarios/novo`);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Page header="Usuários">
      <PrimaryButton text="+ novo usuário" onClick={handleNeUser} />
      <Table columns={columns} title="" data={users} />
    </Page>
  );
};
